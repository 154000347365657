import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { LoadingBar } from 'sb-ui-components';
import { AppState } from '../../types';

interface GlobalLoadingBarProps {
  className?: string;
}

export const GlobalLoadingBar: FunctionComponent<GlobalLoadingBarProps> = props => {
  const { className } = props;
  const { isLoading } = useSelector((state: AppState) => state.loadingBar);
  const { currentlySending: authRequestLoading } = useSelector((state: AppState) => state.auth);

  const baseClassName = 's-global-loading-bar';

  if (isLoading || authRequestLoading) return <LoadingBar className={classNames(baseClassName, className)} />;

  return null;
};
