import { FINISH_LOADING, FinishLoadingAction, START_LOADING, StartLoadingAction } from './global-loading-bar-actions';

export interface LoadingBarState {
  isLoading: boolean;
}

const DEFAULT_STATE: LoadingBarState = {
  isLoading: false,
};

export const loadingBar = (
  state: LoadingBarState = DEFAULT_STATE,
  action: StartLoadingAction | FinishLoadingAction,
) => {
  switch (action.type) {
    case START_LOADING:
      state.isLoading = true;
      return { ...state };
    case FINISH_LOADING:
      state.isLoading = false;
      return { ...state };
    default:
      return state;
  }
};
