import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, IntlShape } from 'react-intl';
import { Button, SimpleMessage, SolarisLogo, ThemeProvider } from 'sb-ui-components';
import AppHabitat from 'src/patterns/habitats/app';
import { AuthenticationFormValues } from '../../models/authentication';
import { AuthenticationMessageStatus } from '../../types';
import AuthenticationForm from '../authentication-form';
import { GlobalLoadingBar } from '../global-loading-bar';
import './authentication-page.css';

interface AuthenticationProps {
  login: (values: AuthenticationFormValues) => void;
  iamLogin: () => void;
  statusMessage: AuthenticationMessageStatus;
}

const Authentication: FunctionComponent<AuthenticationProps & { intl: IntlShape }> = props => {
  const {
    login,
    iamLogin,
    intl: { formatMessage },
  } = props;

  const baseClass = 's-authentication';

  const isClientIdPresent = () => {
    const url: URL = new URL(window.location.href);
    const client_id = url.searchParams.get('client_id');
    return client_id && client_id.length;
  };

  const getStatusMessage = () => {
    const {
      statusMessage,
      intl: { formatMessage },
    } = props;
    switch (statusMessage) {
      case AuthenticationMessageStatus.PRISTINE: {
        return null;
      }
      case AuthenticationMessageStatus.LOGGED_OUT: {
        return <SimpleMessage type="success" text={formatMessage({ id: 'auth.successfullyLoggedOut' })} />;
      }
      case AuthenticationMessageStatus.LOGGED_OUT_FOR_INACTIVITY: {
        return <SimpleMessage type="default" text={formatMessage({ id: 'auth.loggedOutForInactivity' })} />;
      }
      case AuthenticationMessageStatus.WRONG_CREDENTIALS: {
        return <SimpleMessage type="alert" text={formatMessage({ id: 'auth.wrongCredentials' })} />;
      }
      case AuthenticationMessageStatus.NETWORK_INVALID: {
        return <SimpleMessage type="alert" text={formatMessage({ id: 'auth.invalidNetwork' })} />;
      }
      default: {
        return null; // returns null to please React
      }
    }
  };

  return (
    <ThemeProvider theme="dark">
      <AppHabitat>
        <Helmet title={formatMessage({ id: 'pageSectionTitle.login' })} />
        <GlobalLoadingBar className={`${baseClass}__loading-bar`} />
        <div className={baseClass}>
          <div className={`${baseClass}__wrapper`}>
            <div className={`${baseClass}__logo-wrapper`}>
              <SolarisLogo />
              <h1 className={`${baseClass}__title`}>WebUI</h1>
            </div>
            <AuthenticationForm onSubmit={login} />
            {isClientIdPresent() && (
              <Button
                dataCy="cta__iam_login"
                size="big"
                className="s-authentication-form__button"
                handleClick={iamLogin}
                appearance="stroked"
              >
                {formatMessage({ id: 'button.iam' })}
              </Button>
            )}
          </div>
        </div>
        <div className={`${baseClass}__status`}>{getStatusMessage()}</div>
      </AppHabitat>
    </ThemeProvider>
  );
};

export default injectIntl(Authentication);
