import { SagaIterator } from '@redux-saga/types';
import { call, cancel, put, spawn } from 'redux-saga/effects';
import { Notification, notificationTypes } from 'sb-ui-components';
import { FINISH_LOADING, START_LOADING } from 'src/redux/global-loading-bar/global-loading-bar-actions';
import { ApiRequest, ApiRequestParams, buildRequestConfig, hasUnrecoverableError, request } from '../lib/api';
import { EndpointConfig, ResourceError, ResourceResponse } from '../types';
import { createNotification } from './notification';

const defaultApiRequestParams: ApiRequestParams = { queryParams: {}, pathParams: {} };

export function* resourceRequest(
  endpointConfig: EndpointConfig,
  apiRequestParams: ApiRequestParams = defaultApiRequestParams,
  payload?: {},
): SagaIterator<ResourceResponse<unknown>> {
  return yield call(genericResourceRequest, endpointConfig, apiRequestParams, request, payload);
}

export function* genericResourceRequest(
  endpointConfig: EndpointConfig,
  apiRequestParams: ApiRequestParams = defaultApiRequestParams,
  requestSaga: (params: ApiRequest) => void,
  payload?: {},
): SagaIterator<unknown> {
  const apiRequestConfig: ApiRequest = buildRequestConfig(endpointConfig, apiRequestParams, payload);
  try {
    yield put({ type: START_LOADING });
    return yield call(requestSaga, apiRequestConfig);
  } catch (resourceResponse) {
    if (hasUnrecoverableError(resourceResponse)) {
      const error: ResourceError = resourceResponse.error.apiErrors[0];
      const notificaton: Notification = {
        id: error.id,
        title: `${error.title} (${error.status})`,
        message: error.detail,
        topic: 'There was an issue calling the solaris API',
        type: notificationTypes.ERROR,
      };
      yield spawn(createNotification, notificaton);
      yield cancel();
    }
    return resourceResponse;
  } finally {
    yield put({ type: FINISH_LOADING });
  }
}
