import { StandardAction } from 'src/types';

export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';

export interface StartLoadingAction extends StandardAction {
  readonly type: typeof START_LOADING;
}

export interface FinishLoadingAction extends StandardAction {
  readonly type: typeof FINISH_LOADING;
}

export function startLoading(): StartLoadingAction {
  return {
    type: START_LOADING,
  };
}

export function finishLoading(): FinishLoadingAction {
  return {
    type: FINISH_LOADING,
  };
}
